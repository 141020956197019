import * as React from 'react';
import styled from 'styled-components';

export const desktop = 1200;
export const tablet = 1000;
export const mobile = 600;
export const tiny = 300;

type ContainerProps = {
    children: React.ReactNode,
    className?: string,
    colour?: string,
};

export const Container = (props: ContainerProps) => (
    <ContainerWrapper colour={props.colour}>
        <ContainerContent className={props.className}>
            {props.children}
        </ContainerContent>
    </ContainerWrapper>
)

const ContainerWrapper = styled.div`
    height: 100%;
    background-color: ${(props: ContainerProps) => props.colour || 'transparent'}
`;

const ContainerContent = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;

    width: ${desktop}px;

    @media (max-width: ${desktop}px) {
        width: ${tablet}px;
    }

    @media (max-width: ${tablet}px) {
        width: ${mobile}px;
    }

    @media (max-width: ${mobile}px) {
        width: ${tiny}px;
    }
`;

export const ContainerPage = styled.div`
    padding: 0 40px;

    @media (max-width: ${desktop}px) {
        padding: 0 20px;
    }

    @media (max-width: ${tablet}px) {
        padding: 0 10px;
    }

    @media (max-width: ${mobile}px) {
        padding: 5px;
    }
`;
