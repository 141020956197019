export const lightGrey = '#dddfd4';
export const lightYellow = '#fae596';
export const midBlue = '#3fb0ac';
export const darkBlue = '#2a6b74'; // '#173e43';
export const niceBlue =  '#259296'; // '#2ca7ab';
export const niceBlueTint = '#2ca7ab'; // '#9ad6d4';

export const bootstrapLightGrey = '#f5f5f5';

export const black = '#222';

export const lightText = 'whitesmoke';

export const navbarColor = niceBlue;
export const navbarBorderColor = '#28969a';
export const navbarLightText =  lightText;
export const navbarDarkText = navbarColor;
