import React from 'react';
import Helmet from 'react-helmet';
import { Navbar } from './navbar';
import styled, { injectGlobal } from 'styled-components';
import * as colours from '../styling/colours';
import { headerFont, bodyFont } from '../styling/fonts';

injectGlobal`
  html, body {
    font-family: ${bodyFont};
    font-size: 18px;
    background-color: ${colours.bootstrapLightGrey};
  }

  body {
    margin: 0;
    padding: 0;
  }

  h1 {
    font-family: ${headerFont};
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
  }
`;

const PageContent = styled.div`
  min-height: calc(100vh - 100px - 54px);
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
  background-color: ${colours.black};
  height: 100px;
  opacity: 0.95;
`;

const Layout = ({ children }: any) => (
  <div>
    <Helmet title="pre-drinks.com | All the best drinking games for predrinks.">
      <link rel="icon" type="image/png" href={require('../images/favicon.ico')} sizes="16x16" />
      <script>
      {`
        // Google Analytics
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        ga('create', 'UA-99879332-1', 'auto');
        ga('send', 'pageview');
      `}
      </script>
      <html lang="en" />
    </Helmet>
    <Navbar />
    <PageContent>
      {children}
    </PageContent>
    <Footer>
      &copy; {new Date().getFullYear()} - pre-drinks.com
    </Footer>
  </div>
)

export default Layout;
