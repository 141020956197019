import * as React from 'react';
import Link from 'gatsby-link'
import styled from 'styled-components';
import { Container, tiny, mobile, tablet, desktop } from './container';
import * as fonts from '../styling/fonts';
import * as colours from '../styling/colours';

const urlMatches = (toMatch: string) =>
    typeof window !== 'undefined' && window.location.pathname.endsWith(toMatch);

export class Navbar extends React.Component<{}, { isMenuExpanded: boolean }> {
    state = { isMenuExpanded: false };

    toggleMenu = () => this.setState(state => ({ isMenuExpanded: !state.isMenuExpanded }));

    render() {
        return (
            <>
                <NavbarWrapper>
                    <NavbarContentWrapper>
                        <BrandLink className="brand-link" to="/">
                            <BrandIcon src={require('../images/pint.svg')} alt="Pint of beer." />
                            <span>pre-drinks.com</span>
                        </BrandLink>
                        <ShowMobile>
                            <MenuToggle onClick={this.toggleMenu}>
                                <MenuIcon src={require('../images/menu-icon.svg')} alt="Menu icon." />
                            </MenuToggle>
                        </ShowMobile>
                        <ShowDesktop>
                            <PageLink to="/DrinkingGames" active={urlMatches('/DrinkingGames')}>
                                <PageIcon src={require('../images/icons/all-games.svg')} alt="A to Z." />
                                <span>A-Z</span>
                            </PageLink>
                            <PageLink to="/DrinkingGames/CardDrinkingGames" active={urlMatches('/CardDrinkingGames')}>
                                <PageIcon src={require('../images/icons/card-games.svg')} alt="Card games." />
                                <span>Card Games</span>
                            </PageLink>
                            <PageLink to="/DrinkingGames/WordDrinkingGames" active={urlMatches('/WordDrinkingGames')}>
                                <PageIcon src={require('../images/icons/word-games.svg')} alt="Word games." />
                                <span>Word Games</span>
                            </PageLink>
                            <PageLink to="/DrinkingGames/OtherDrinkingGames" active={urlMatches('/OtherDrinkingGames')}>
                                <PageIcon src={require('../images/icons/other-games.svg')} alt="Other games." />
                                <span>Other Games</span>
                            </PageLink>
                        </ShowDesktop>
                    </NavbarContentWrapper>
                </NavbarWrapper>
                {this.state.isMenuExpanded && (
                    <MenuWrapper>
                        <Container>
                            <PageLink to="/DrinkingGames" active={urlMatches('/DrinkingGames')}>
                                <PageIcon src={require('../images/icons/all-games.svg')} alt="A to Z." />
                                <span>A-Z</span>
                            </PageLink>
                            <PageLink to="/DrinkingGames/CardDrinkingGames" active={urlMatches('/CardDrinkingGames')}>
                                <PageIcon src={require('../images/icons/card-games.svg')} alt="Card games." />
                                <span>Card Games</span>
                            </PageLink>
                            <PageLink to="/DrinkingGames/WordDrinkingGames" active={urlMatches('/WordDrinkingGames')}>
                                <PageIcon src={require('../images/icons/word-games.svg')} alt="Word games." />
                                <span>Word Games</span>
                            </PageLink>
                            <PageLink to="/DrinkingGames/OtherDrinkingGames" active={urlMatches('/OtherDrinkingGames')}>
                                <PageIcon src={require('../images/icons/other-games.svg')} alt="Other games." />
                                <span>Other Games</span>
                            </PageLink>
                        </Container>
                    </MenuWrapper>
                )}
            </>
        );
    }
}

export const navbarContentHeight = 50;
export const navbarBorderHeight = 4;
export const navbarHeight = navbarContentHeight + navbarBorderHeight;

const navbarColour = colours.niceBlue;
const navbarLightText =  colours.lightText;
const navbarDarkText = colours.navbarColor;

const ShowMobile = styled.div`
    flex: 1;
    display: none;

    @media (max-width: ${tablet}px) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;

const ShowDesktop = styled.div`
    flex: 1;
    display: flex;
    height: 100%;
    justify-content: flex-start;

    @media (max-width: ${tablet}px) {
        display: none;
    }
`;

const MenuToggle = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    height: ${navbarContentHeight * 0.75}px;
    width: ${navbarContentHeight * 0.75}px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    opacity: 0.75;
    transition: opacity 0.25s ease;

    &:hover {
        opacity: 1;
    }
`;

const MenuIcon = styled.img`
    height: 100%;
    width: 100%;
`;

const NavbarWrapper = styled.nav`
    height: ${navbarContentHeight}px;
    background-color: ${navbarColour};
    border-bottom: solid ${navbarBorderHeight}px rgba(0, 0, 0, 0.1);
`;

const NavbarContentWrapper = styled(Container)`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const BrandIcon = styled.img`
    display: inline-block;
    height: 70%;
    margin-right: 10px;
    font-family: 'Carter One', cursive;
`;

const BrandLink = styled(Link)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-right: 25px;
    font-family: ${fonts.headerFont};
    text-decoration: none;
    font-size: 1.25em;
    color: white;

    transition: opacity 0.25s ease;
    &:hover {
        opacity: 0.85;
    }
    &:active, &:focus {
        opacity: 1;
    }
`;

type PageLinkProps = {
    active: boolean,
};

const PageLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    height: 100%;
    padding: 0 10px;
    font-size: 0.85em;
    font-weight: bold;
    margin-left: 1.2vw;
    letter-spacing: 1px;
    transition: opacity 0.25s ease;
    opacity: ${(props: PageLinkProps) => props.active ? 1 : 0.65};
    &:hover, &:active, &:focus {
        opacity: 1;
    }

    span {
        margin-left: 0.75vw;
    }
`;

const PageIcon = styled.img`
    display: inline-block;
    height: 50%;
`;

const MenuWrapper = styled.div`
    display: block;
    width: 100%;
    padding: 10px;
    background-color: ${colours.darkBlue};
    border-bottom: solid 2px ${colours.niceBlue};

    ${PageLink} {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 15px 0;
        padding: 10px 0;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    ${PageIcon} {
        height: ${navbarContentHeight * 0.5}px;
        margin-right: 10px;
    }
`;
